import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { colors } from '@core/theme/units/palette';
import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './As.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const AsIconComponent: FC<IconProps> = ({ classes, color = colors.white.lightest }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_231_3023)">
        <path
          d="M11.1266 7.44987L5.49017 17.3446C5.34542 17.6154 4.95655 17.6154 4.77076 17.3446L2.47425 13.3186C2.35111 13.1112 2.35111 12.8819 2.47425 12.6745L8.05672 2.88676C8.09777 2.8038 8.20147 2.76231 8.303 2.76231C8.40454 2.76231 8.50824 2.8038 8.54929 2.88676L8.57521 2.9348L11.1266 7.44987Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M19.2216 12.5937H14.0906L11.1136 7.42801C9.3896 4.39977 8.54921 2.88673 8.54921 2.88673C8.50816 2.80377 8.40446 2.76229 8.30293 2.76229C8.20139 2.76229 8.09769 2.80377 8.05664 2.88673C8.20139 2.616 8.48872 2.45007 8.8171 2.45007H13.5786C13.8055 2.45007 14.0107 2.57452 14.1122 2.78194L19.47 12.1571C19.5996 12.3972 19.4246 12.5937 19.2237 12.5937H19.2216Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M19.5047 12.2226C19.6559 12.4999 19.6537 12.7378 19.5112 12.9867L17.0483 17.3424C16.9662 17.4669 16.8431 17.5498 16.6983 17.5498H13.8855C13.7407 17.5498 13.6176 17.4669 13.5355 17.3424L11.1958 13.2138C11.051 12.9431 11.2368 12.5916 11.5458 12.5916H19.2433C19.3837 12.5916 19.617 12.4125 19.4701 12.1549L19.5047 12.2204V12.2226Z"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_231_3023">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const AsIcon = withStyles(styles)(AsIconComponent);
